import React, { ReactElement, useCallback } from 'react';
import classNames from 'classnames';
import { IResponsiveContainerProps } from '../ResponsiveContainer.types';
import { TestIds } from '../constants';

const OverflowWrapper: React.FC<{ className: string }> = ({
  children,
  className,
}) => (
  <div className={className} data-testid={TestIds.overflow}>
    {children}
  </div>
);

const ResponsiveContainer: React.FC<IResponsiveContainerProps> = ({
  containerLayoutClassName,
  overlowWrapperClassName,
  hasOverflow,
  children,
  role,
  extraRootClass = '',
}) => {
  const wrapWithOverflowWrapperIfNeeded = useCallback(
    (reactChildren: ReactElement) =>
      hasOverflow ? (
        <OverflowWrapper
          className={classNames(overlowWrapperClassName, extraRootClass)}
        >
          {reactChildren}
        </OverflowWrapper>
      ) : (
        reactChildren
      ),
    [extraRootClass, hasOverflow, overlowWrapperClassName],
  );

  return wrapWithOverflowWrapperIfNeeded(
    <div
      className={
        hasOverflow
          ? containerLayoutClassName
          : classNames(containerLayoutClassName, extraRootClass)
      }
      data-testid={TestIds.content}
      {...(role ? { role } : {})}
    >
      {children()}
    </div>,
  );
};

export default ResponsiveContainer;
